$duration: 3000ms;
$loader-size: 50px;
$pip-size: 8px;

$cube-size: 400px;

#global-loader-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  backdrop-filter: blur(6px);
  background: #FFFFFF38;
  display: flex;
  align-items: center;
  justify-content: center;
}

.global-loader-spinner {
  animation: rotate 1.2s ease-in-out infinite;
}

.global-loader {
  position: relative;
  width: $loader-size;
  height: $loader-size;
  border-radius: 50%;
  animation: $duration * 1.5 linear 0s normal none infinite running;
  animation-name: rotate;

  span::after {
    content: '';
    width: $pip-size;
    height: $pip-size;
    display: block;
    position: absolute;
    border-radius: 50%;
    background-color: #ffaf04;
    transform-origin: $loader-size/2 $loader-size/2;
    animation-duration: $duration;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}

.global-success {
  height: 165px;
  width: 165px;
  background: url('../../assets/gif/check2.gif');
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@for $i from 0 through 5 {
  $start: $i * 20;
  $delay: 900 - ($i * 125);
  $mid: 180 + $start;
  $finish: 360 + $start;

  .pip-#{$i} {
    &::after {
      transform: rotate(#{$start}deg);
      animation-name: rotate-#{$i};
      animation-delay: #{$delay}ms;
    }
  }

  @keyframes rotate-#{$i} {
    0%,
    20% {
      transform: rotate(#{$start}deg);
    }
    40%,
    60% {
      transform: rotate(#{$mid}deg);
    }
    80%,
    100% {
      transform: rotate(#{$finish}deg);
    }
  }
}

@keyframes opacity {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.cube-load-container {
  height: $cube-size;
  width: $cube-size;
  transform: scale(0.4);
  direction: ltr;
}

.cube {
  width: $cube-size / 2;
  height: $cube-size / 2;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cube > div {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border-radius: 30px;
}
.cube1 {
  animation-name: m1;
  animation-delay: 0.25s;
}
.cube2 {
  animation-name: m2;
  animation-delay: 0.5s;
}
.cube3 {
  transform: translate(calc($cube-size / 2), calc(-1 * ($cube-size / 2)));
  animation-name: m3;
  animation-delay: 0.75s;
}
.cube1 > div {
  background-color: #6f97fc;
}
.cube2 > div {
  background-color: #4c7ffc;
}
.cube3 > div {
  background-color: #356ffc;
}

@keyframes m3 {
  0% {
    transform: translate(calc($cube-size / 2), calc(-1 * ($cube-size / 2)));
  }
  12.5% {
    transform: translate(0px, calc(-1 * ($cube-size / 2)));
  }
  25% {
    transform: translate(0px, calc(-1 * ($cube-size / 2)));
  }
  37.5% {
    transform: translate(0px, calc(-1 * $cube-size));
  }
  50% {
    transform: translate(0px, calc(-1 * $cube-size));
  }
  62.5% {
    transform: translate(calc($cube-size / 2), calc(-1 * $cube-size));
  }
  75% {
    transform: translate(calc($cube-size / 2), calc(-1 * $cube-size));
  }
  87.5% {
    transform: translate(calc($cube-size / 2), calc(-1 * ($cube-size / 2)));
  }
}

@keyframes m2 {
  0% {
    transform: translate(0px, 0px);
  }
  12.5% {
    transform: translate(0, calc(-1 * ($cube-size / 2)));
  }
  25% {
    transform: translate(0, calc(-1 * ($cube-size / 2)));
  }
  37.5% {
    transform: translate(calc($cube-size / 2), calc(-1 * ($cube-size / 2)));
  }
  50% {
    transform: translate(calc($cube-size / 2), calc(-1 * ($cube-size / 2)));
  }
  62.5% {
    transform: translate(calc($cube-size / 2), 0);
  }
  75% {
    transform: translate(calc($cube-size / 2), 0);
  }
  87.5% {
    transform: translate(0px, 0px);
  }
}

@keyframes m1 {
  0% {
    transform: translate(0, 0);
  }
  12.5% {
    transform: translate(calc($cube-size / 2), 0px);
  }
  25% {
    transform: translate(calc($cube-size / 2), 0px);
  }
  37.5% {
    transform: translate(calc($cube-size / 2), calc($cube-size / 2));
  }
  50% {
    transform: translate(calc($cube-size / 2), calc($cube-size / 2));
  }
  62.5% {
    transform: translate(0, calc($cube-size / 2));
  }
  75% {
    transform: translate(0, calc($cube-size / 2));
  }
  87.5% {
    transform: translate(0, 0);
  }
}
