:root {
  --loginWidth: 100%;
  --loginHeight: 100%;
  --loginRounded: 24px;
}

.login-dialog, .ryd-dialog {
  @apply h-full max-h-screen sm:max-h-[49.75rem] w-full max-w-[36.25rem] #{!important};

  .mat-mdc-dialog-surface {
    @apply rounded-none sm:rounded-[--loginRounded] #{!important};
  }
}

.from-bottom-to-dialog {
  .mat-bottom-sheet-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    border-radius: 24px !important;
    width: 570px;
  }
}
