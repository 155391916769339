@use 'global-loader';
@use 'adaptive';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #23325166 transparent;

    &::-webkit-scrollbar {
      width: 1rem;
      height: 1rem;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      // border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #23325166;
      border: 5px solid transparent;
      border-radius: 9999px;
      background-clip: content-box;
    }
  }

  .product-details-dialog {
    .mat-mdc-dialog-surface {
      --mdc-dialog-container-shape: 24px;
      @apply overflow-hidden #{!important};
    }
  }

  .ryd-product-details-dialog {
    @apply max-h-[90vh] #{!important};
    .mat-bottom-sheet-container {
      @apply overflow-hidden p-0 #{!important};
    }

    .mat-mdc-dialog-surface {
      @apply rounded-3xl #{!important};
    }
  }

  .sup-button {
    .mdc-button__label {
      @apply contents;
    }
  }

  .ryd-button {
    @apply flex h-auto flex-row-reverse items-center justify-center gap-3 rounded-lg font-semibold shadow-ryd-xs transition-all #{!important};

    .mdc-button__label {
      @apply contents;
    }

    .mat-mdc-button-persistent-ripple::before {
      @apply bg-transparent #{!important};
    }

    .mat-icon {
      @apply mr-[-2px];
    }

    &:not([mat-fab]) .mat-icon {
      @apply mx-0 h-6 w-6 #{!important};
    }

    &,
    &.sm {
      @apply h-9 px-3.5 text-sm #{!important};

      &[mat-fab] {
        @apply w-9 px-0 #{!important};

        .mat-icon {
          @apply mx-0 h-5 w-5 #{!important};
        }
      }

      &:not([mat-fab]) .mat-icon {
        @apply mx-0 h-5 w-5 #{!important};
      }
    }

    &.xxs {
      @apply h-4 rounded p-0 text-sm #{!important};
    }

    &.xs {
      @apply h-7 rounded px-2 text-sm #{!important};
    }

    &.md {
      @apply h-10 px-4 text-sm #{!important};

      &[mat-fab] {
        @apply w-10 px-0 #{!important};
      }
    }

    &.lg {
      @apply h-11 px-[1.125rem] text-base #{!important};

      &[mat-fab] {
        @apply w-11 px-0 #{!important};
      }
    }

    &.xl {
      @apply h-12 px-5 text-base #{!important};

      &[mat-fab] {
        @apply w-12 px-0 #{!important};
      }
    }

    &.xl-2 {
      @apply h-[3.75rem] px-7 text-lg #{!important};

      &[mat-fab] {
        @apply w-[3.75rem] px-0 #{!important};
      }
    }

    &.basket-button {
      @apply grid grid-cols-3 pe-3 ps-2 text-sm #{!important};
    }

    &[mat-flat-button],
    &[mat-fab] {
      &,
      &.primary {
        @apply bg-ryd-brand-600 text-white hover:bg-ryd-brand-700 active:bg-ryd-brand-600 active:shadow-ryd-brand-50 disabled:bg-ryd-gray-300 disabled:shadow-ryd-xs #{!important};
      }

      &.secondary {
        @apply bg-ryd-brand-100 text-ryd-brand-700 hover:bg-ryd-brand-200 active:bg-ryd-brand-100 active:shadow-ryd-brand-200 disabled:bg-ryd-gray-25 disabled:text-ryd-brand-200 disabled:shadow-ryd-xs #{!important};
      }

      &.danger {
        @apply bg-ryd-error-600 text-white hover:bg-ryd-error-700 active:bg-ryd-error-600 active:shadow-ryd-error-100 disabled:bg-ryd-error-200 disabled:shadow-ryd-xs #{!important};
      }

      &.blue {
        @apply bg-[#1877F2] text-white hover:bg-[#0C63D4] active:bg-[#1877F2] active:shadow-ryd-gray-100 #{!important};
      }

      &.black {
        @apply bg-black text-white active:shadow-ryd-gray-100 #{!important};
      }
    }

    &[mat-stroked-button],
    &.stroked,
    &[mat-fab].stroked {
      @apply border border-solid border-ryd-gray-300 bg-white text-ryd-gray-700 hover:bg-ryd-gray-25 active:bg-white active:shadow-ryd-gray-100 disabled:border-ryd-gray-100 disabled:bg-white disabled:text-ryd-gray-300 disabled:shadow-ryd-xs #{!important};

      &.badged {
        @apply relative border-ryd-brand-600 #{!important};

        &:after {
          @apply absolute right-[-3px] top-[-2px] h-2.5 w-2.5 rounded-full bg-ryd-brand-600 content-[""];
        }
      }
    }

    &[mat-button] {
      @apply h-auto bg-transparent text-ryd-gray-700 shadow-none hover:text-gray-800 disabled:text-ryd-gray-300 #{!important};

      .mat-mdc-button-ripple {
        display: none;
      }

      .mat-icon {
        @apply h-4 w-4 #{!important};
      }

      &.primary {
        @apply text-ryd-brand-600 hover:text-ryd-brand-700 disabled:text-ryd-brand-300 #{!important};
      }
    }

    &[mat-icon-button] {
      @apply h-9 w-9 bg-transparent p-0 shadow-none hover:bg-ryd-gray-200 #{!important};

      .mat-mdc-button-ripple {
        display: none;
      }

      .mat-icon {
        --mdc-icon-button-icon-size: 20px;
        @apply h-auto w-auto #{!important};
      }

      &.xxs .mat-icon {
        --mdc-icon-button-icon-size: 16px !important;
      }

      &.sm .mat-icon {
        --mdc-icon-button-icon-size: 20px !important;
      }

      &.no-hover-bg {
        @apply bg-transparent #{!important};
      }

      &[disabled] {
        @apply opacity-35;
      }
    }
  }

  .ryd-form-field {
    .mat-mdc-text-field-wrapper {
      @apply overflow-visible p-0 #{!important};
    }
  }

  .ryd-input {
    .ryd-label {
      @apply text-sm font-medium;
    }

    .ryd-credit-card-icon {
      @apply ml-3.5 mr-1 rtl:ml-1 rtl:mr-3.5;

      mat-icon {
        @apply h-[24px] w-[34px] #{!important};
      }
    }

    textarea {
      resize: none;
      @apply h-28 #{!important};
    }

    .mat-mdc-form-field {
      @apply w-full;

      .mat-mdc-form-field-infix {
        @apply min-h-0 py-2.5 #{!important};
      }

      .mat-mdc-form-field-focus-overlay {
        @apply bg-transparent;
      }

      .mat-mdc-text-field-wrapper {
        @apply mt-1.5 rounded-lg border border-ryd-gray-300 bg-transparent shadow-ryd-xs outline-0 transition-all hover:border-ryd-brand-400;

        .mat-icon {
          @apply h-5;
        }
      }

      .mdc-text-field--disabled {
        @apply bg-ryd-gray-50 hover:border-inherit;
      }

      .mdc-text-field--invalid {
        @apply border-ryd-error-300;
      }

      .mdc-text-field--focused {
        @apply border-ryd-brand-400 bg-transparent text-sup-primary shadow-ryd-brand-50;
      }

      .mat-mdc-input-element {
        @apply text-ryd-gray-800 placeholder-ryd-gray-400 caret-ryd-gray-800;

        &[type='password'] {
          @apply text-ryd-gray-600;
        }

        &[type='search']::-webkit-search-cancel-button {
          -webkit-appearance: none;
          @apply h-5 content-ryd-close;
        }
      }

      .mat-mdc-form-field-subscript-wrapper {
        height: 20px;
      }

      .mat-mdc-form-field-icon-suffix {
        @apply flex items-center gap-2 px-3.5;

        .mat-icon {
          @apply p-0;
        }

        .mat-datepicker-toggle {
          .mdc-icon-button {
            @apply h-full w-full p-0;

            .mdc-icon-button__ripple,
            .mat-ripple {
              @apply hidden;
            }

            .mat-icon {
              svg {
                @apply h-full w-full;
              }
            }
          }
        }
      }

      .mat-mdc-form-field-icon-prefix {
        @apply flex items-center gap-2 ps-3.5;

        .mat-icon {
          @apply p-0;
        }
      }

      .mat-mdc-form-field-error-wrapper,
      .mat-mdc-form-field-hint-wrapper {
        @apply p-0 text-sm;
      }

      .mat-mdc-form-field-error {
        @apply text-sm text-ryd-error-500;
      }
      .mat-mdc-form-field-hint {
        @apply text-ryd-gray-500;
      }

      .mdc-line-ripple {
        @apply hidden;
      }

      &.ryd-hide-field-errors {
        .mat-mdc-form-field-subscript-wrapper {
          height: 0;
        }
      }
    }
  }

  .ryd-select {
    @apply cursor-pointer;

    .mat-mdc-select-arrow {
      @apply mb-[-2px] h-5 w-5 content-ryd-chevron-down;
    }
  }

  .ryd-input.phone {
    .mat-mdc-form-field-flex {
      @apply gap-2;
    }

    .ryd-input-invalid {
      .mat-mdc-text-field-wrapper {
        @apply border-ryd-error-300;
      }
    }

    .mat-mdc-form-field-icon-suffix {
      @apply h-[44px] p-0;

      .mat-mdc-select {
        @apply m-0 flex h-full w-20 items-center border-y-0 border-l-0 border-r border-ryd-gray-300 px-4 shadow-none ltr:pl-0;

        .mat-mdc-select-trigger {
          @apply flex-row-reverse gap-2;

          .mat-mdc-select-value {
            @apply overflow-visible text-center;
          }

          .mat-mdc-select-arrow-wrapper {
            @apply w-3.5;
          }
        }

        .mat-mdc-form-field-subscript-wrapper {
          @apply hidden;
        }
      }
    }
  }

  .ryd-badge {
    @apply flex h-fit w-fit items-center justify-center gap-2 rounded-2xl px-2 pb-1 pt-1.5 text-xs font-medium leading-none before:hidden;

    &.xs {
      @apply px-1 pb-0 pt-0.5;
    }

    .mat-icon {
      @apply h-3 w-3 -translate-y-[1px];
    }

    &.square {
      @apply rounded py-1;
    }

    &,
    &.primary {
      @apply bg-ryd-brand-600 text-white before:bg-white;
    }

    &.secondary {
      @apply bg-ryd-brand-100 text-ryd-brand-700 before:bg-ryd-brand-700;
    }

    &.secondary-gray {
      @apply bg-ryd-gray-100 text-ryd-gray-700 before:bg-ryd-gray-700;
    }

    &.outline {
      @apply border border-ryd-gray-200 bg-white text-ryd-gray-700 outline-0 before:bg-ryd-gray-700;
    }

    &.outline-gray {
      @apply border border-ryd-gray-200 bg-ryd-gray-100 text-ryd-gray-700 outline-0 before:bg-ryd-gray-700;
    }

    &.success {
      @apply bg-ryd-success-600 text-white before:bg-white;
    }

    &.no-outline {
      @apply bg-white text-ryd-gray-700 before:bg-ryd-gray-700;
    }

    &.dotted {
      &:before {
        @apply mr-0.5 block h-2 w-2 translate-y-[-20%] rounded-full content-[""];
      }

      &.dot-success {
        @apply before:bg-ryd-success-600;
      }

      &.dot-warning {
        @apply before:bg-ryd-warning-500;
      }

      &.dot-danger {
        @apply before:bg-ryd-error-600;
      }
    }

    &.counter {
      @apply h-5 w-5 bg-ryd-error-600 p-0 pl-px pt-0.5 text-xs;
    }
  }

  .ryd-badge-parent {
    @apply relative overflow-visible #{!important};

    .ryd-badge {
      @apply absolute right-[-0.375rem] top-[-0.375rem];
    }
  }

  .ryd-tag {
    @apply rounded-md border border-ryd-gray-300 px-2 py-1 text-ryd-gray-800 hover:border-ryd-brand-200 active:border-ryd-brand-500;

    &.primary {
      @apply border-ryd-brand-500;
    }

    &.primary-fill {
      @apply border-ryd-brand-500 bg-ryd-brand-500 text-white;
    }

    &[disabled] {
      @apply border-0 bg-ryd-gray-50 text-ryd-gray-300;
    }
  }

  .ryd-toggle {
    .mdc-switch {
      @apply h-5 #{!important};

      .mdc-switch__track {
        @apply h-full rounded-xl shadow-none transition-shadow before:border-none before:bg-ryd-gray-100 after:border-none after:bg-ryd-brand-600 #{!important};
      }

      .mdc-switch__handle {
        @apply left-[22px] after:rtl:left-[-3px] h-4 w-4 translate-y-[-50%] shadow-ryd-xs before:rounded-full after:rounded-full after:bg-white #{!important};
      }

      .mdc-switch__icons,
      .mdc-switch__shadow,
      .mdc-switch__ripple {
        @apply hidden;
      }
    }

    &.cdk-focused {
      .mdc-switch__track {
        @apply shadow-ryd-brand-100;
      }
    }

    &.md {
      .mdc-switch {
        @apply h-6 w-11 #{!important};

        .mdc-switch__handle {
          @apply h-[18px] w-[18px] translate-y-[-50%] rounded-full ltr:translate-y-[-48%] #{!important};
        }
      }

      .mdc-switch--unselected {
        .mdc-switch__handle-track {
          @apply translate-x-[-20px] #{!important};
        }
      }
    }
  }

  .ryd-checkbox {
    .mdc-checkbox {
      .mdc-checkbox__background {
        @apply h-4 w-4 rounded border border-ryd-gray-300 bg-white transition-all #{!important};

        .mdc-checkbox__checkmark,
        .mdc-checkbox__mixedmark {
          @apply hidden;
        }
      }

      .mdc-checkbox__ripple,
      .mat-ripple {
        @apply hidden;
      }

      &:hover {
        .mdc-checkbox__background {
          @apply border-ryd-brand-600 bg-ryd-brand-50 #{!important};
        }
      }

      &:active {
        .mdc-checkbox__background {
          @apply border-ryd-brand-600 bg-ryd-brand-50 shadow-ryd-brand-100 #{!important};
        }
      }
    }

    &.mat-mdc-checkbox-checked {
      .mdc-checkbox__background {
        @apply border-ryd-brand-600 bg-ryd-brand-50 content-ryd-checked #{!important};
      }
    }

    .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background {
      @apply content-ryd-indeterminate #{!important};
    }

    .mdc-checkbox__native-control:indeterminate:not([disabled]) ~ .mdc-checkbox__background {
      @apply border-ryd-brand-600 bg-ryd-brand-50 #{!important};
    }

    &.mat-mdc-checkbox-disabled {
      .mdc-checkbox__background {
        @apply border-ryd-gray-800 bg-ryd-gray-300 opacity-10 #{!important};
      }
    }

    &.md {
      .mdc-checkbox__background {
        @apply h-5 w-5 #{!important};
      }
    }

    &.circle {
      .mdc-checkbox__background {
        @apply rounded-full p-[2px] pt-[3px] #{!important};
      }

      &.mat-mdc-checkbox-checked {
        .mdc-checkbox__background {
          @apply border-ryd-brand-600 bg-ryd-brand-600 content-ryd-checked-white #{!important};
        }
      }

      &.md {
        .mdc-checkbox__background {
          @apply rounded-full p-0.5 pt-1 #{!important};
        }
      }
    }
  }

  .ryd-radio {
    &.mat-mdc-radio-button {
      .mat-ripple {
        @apply hidden;
      }

      .mdc-radio {
        @apply p-0;
      }

      .mdc-radio__background {
        @apply h-4 w-4 before:hidden #{!important};

        .mdc-radio__outer-circle {
          @apply border border-ryd-gray-300 transition-all #{!important};
        }

        .mdc-radio__inner-circle {
          @apply left-[0.5px] top-[0.5px] h-1.5 w-1.5 border-[7px] border-ryd-brand-600 #{!important};
        }
      }

      &:hover {
        .mdc-radio__background {
          .mdc-radio__outer-circle {
            @apply border border-ryd-brand-600 bg-ryd-brand-50 #{!important};
          }
        }
      }

      &:active {
        .mdc-radio__background {
          .mdc-radio__outer-circle {
            @apply shadow-ryd-brand-100 #{!important};
          }
        }
      }

      &.mat-mdc-radio-checked {
        .mdc-radio__background {
          .mdc-radio__outer-circle {
            @apply border border-ryd-brand-600 bg-ryd-brand-50 #{!important};
          }
        }
      }
    }

    &.check {
      .mdc-radio__background {
        .mdc-radio__outer-circle {
          @apply border border-ryd-gray-300 pl-[2.5px] pr-[2px] pt-px transition-shadow #{!important};
        }

        .mdc-radio__inner-circle {
          @apply hidden;
        }
      }

      &.mat-mdc-radio-checked {
        .mdc-radio__background {
          .mdc-radio__outer-circle {
            @apply bg-ryd-brand-600 content-ryd-checked-white #{!important};
          }
        }
      }

      &.md {
        .mdc-radio__background {
          .mdc-radio__outer-circle {
            @apply pt-[3px] #{!important};
          }
        }
      }
    }

    &.md {
      .mdc-radio__background {
        @apply h-5 w-5 #{!important};

        .mdc-radio__inner-circle {
          @apply h-2 w-2 border-[9px] border-ryd-brand-600 #{!important};
        }
      }
    }
  }

  .ryd-item {
    @apply cursor-pointer rounded-lg border border-ryd-gray-200 p-4 transition-all;

    &:hover {
      @apply border-ryd-brand-200 bg-ryd-brand-25;
    }

    &:active {
      @apply shadow-ryd-brand-100;
    }
  }

  .ryd-checkbox-item,
  .ryd-radio-item {
    .mdc-form-field {
      @apply ryd-item relative flex flex-row-reverse p-0;

      .mdc-checkbox,
      .mdc-radio {
        @apply static me-4;

        .mdc-checkbox__background {
          @apply static;
        }
      }

      .mdc-label {
        @apply flex h-full w-full cursor-pointer items-center gap-2 p-4;
      }
    }

    .mat-mdc-checkbox-touch-target,
    .mat-mdc-radio-touch-target {
      @apply h-full w-full;
    }

    &.mat-mdc-checkbox-checked,
    &.mat-mdc-radio-checked {
      .mdc-form-field {
        @apply border-ryd-brand-200 bg-ryd-brand-50;
      }

      &:hover {
        .mdc-form-field {
          @apply border-ryd-brand-200 bg-ryd-brand-25;
        }
      }

      .ryd-icon {
        @apply border-ryd-brand-200 bg-ryd-brand-100 #{!important};
        path {
          @apply stroke-ryd-brand-600 #{!important};
        }
      }
    }

    &.credit-card {
      @apply block w-[8.25rem];

      .mdc-form-field {
        @apply items-start p-3.5;

        .mdc-checkbox,
        .mdc-radio {
          @apply pt-1;
        }

        .mdc-label {
          @apply flex-col items-start;

          mat-icon {
            @apply w-[2.125rem];
          }
        }
      }
    }
  }

  .ryd-icon {
    @apply rounded-full #{!important};

    svg {
      @apply mr-[-0.5px] mt-[-0.5px];
    }

    &,
    &.primary {
      @apply bg-ryd-brand-100 outline-ryd-brand-50;

      &.dark-outline {
        @apply bg-ryd-brand-600 outline-ryd-brand-700;
      }

      path {
        @apply stroke-ryd-brand-600;
      }
    }

    &.gray {
      @apply bg-ryd-gray-100 outline-ryd-gray-50;

      &.dark-outline {
        @apply bg-ryd-gray-600 outline-ryd-gray-700;
      }

      path {
        @apply stroke-ryd-gray-600;
      }
    }

    &.danger {
      @apply bg-ryd-error-100 outline-ryd-error-50;

      &.dark-outline {
        @apply bg-ryd-error-500 outline-ryd-error-600;
      }

      path {
        @apply stroke-ryd-error-600;
      }
    }

    &.warning {
      @apply bg-ryd-warning-100 outline-ryd-warning-50;

      &.dark-outline {
        @apply bg-ryd-warning-500 outline-ryd-warning-600;
      }

      path {
        @apply stroke-ryd-warning-600;
      }
    }
    &.success {
      @apply bg-ryd-success-100 outline-ryd-success-50;

      &.dark-outline {
        @apply bg-ryd-success-500 outline-ryd-success-600;
      }

      path {
        @apply stroke-ryd-success-600;
      }
    }

    &.purple {
      @apply bg-ryd-brand-100 outline-ryd-brand-50;

      &.dark-outline {
        @apply bg-ryd-brand-600 outline-ryd-brand-700;
      }

      path {
        @apply stroke-ryd-brand-600;
      }
    }

    &,
    &.xs {
      @apply h-6 w-6 p-1.5 #{!important};
    }

    &.xxs {
      @apply flex h-5 w-5 items-center justify-center p-0 #{!important};

      svg {
        @apply h-2.5 w-2.5 #{!important};
      }
    }

    &.sm {
      @apply h-8 w-8 p-2 #{!important};
    }

    &.md {
      @apply h-10 w-10 p-2.5 #{!important};
    }

    &.lg {
      @apply h-12 w-12 p-3 #{!important};
    }

    &.xl {
      @apply h-14 w-14 p-3.5 #{!important};
    }

    &.light-outline,
    &.dark-outline {
      @apply outline;

      &,
      &.xs {
        @apply outline-[2px] outline-offset-[-1px];
      }

      &.sm {
        @apply outline-[4px] outline-offset-[-2px];
      }

      &.md {
        @apply outline-[6px] outline-offset-[-3px];
      }

      &.lg {
        @apply outline-[8px] outline-offset-[-4px];
      }

      &.xl {
        @apply outline-[10px] outline-offset-[-5px];
      }
    }

    &.dark-outline {
      path {
        @apply stroke-white #{!important};
      }
    }

    &.square {
      @apply rounded-xl #{!important};

      &,
      &.dark {
        @apply bg-ryd-brand-700;

        path {
          @apply stroke-white;
        }
      }

      &.mid {
        @apply bg-ryd-brand-500;

        path {
          @apply stroke-white;
        }
      }

      &.light {
        @apply bg-ryd-brand-50;

        path {
          @apply stroke-ryd-brand-600;
        }
      }

      &.indigo {
        @apply border border-ryd-indigo-100 bg-ryd-indigo-50;

        path {
          @apply stroke-ryd-indigo-600;
        }
      }

      &.white {
        @apply border border-ryd-gray-200 bg-white;

        path {
          @apply stroke-ryd-gray-700;
        }
      }

      &,
      &.xs {
        @apply rounded #{!important};
      }

      &.sm {
        @apply rounded-md #{!important};
      }

      &.md {
        @apply rounded-lg #{!important};
      }

      &.lg {
        @apply rounded-[0.625rem] #{!important};
      }

      &.xl {
        @apply rounded-xl #{!important};
      }
    }
  }

  .ryd-tooltip {
    .mdc-tooltip__surface {
      @apply overflow-visible rounded-lg bg-white px-3 py-2 text-xs text-ryd-gray-700 shadow-ryd-lg #{!important};

      &:after {
        @apply absolute left-1/2 top-0 h-3 w-3 translate-x-[-50%] translate-y-[-50%] rotate-45 bg-inherit content-[""];
      }
    }

    &.dark {
      .mdc-tooltip__surface {
        @apply bg-ryd-gray-900 text-white #{!important};
      }
    }
  }

  .ryd-modal {
    @apply max-w-full #{!important};

    .mat-mdc-dialog-surface {
      @apply w-[21.438rem] max-w-[calc(100vw-32px)] rounded-xl p-4 shadow-ryd-xl #{!important};
    }
  }

  .ryd-overlay {
    @apply fixed left-0 top-0 z-[110] h-screen w-screen bg-ryd-gray-800/40;
  }

  .ryd-sidebar {
    @apply fixed start-0 top-0 z-[120] me-3.5 h-screen w-[calc(100%-15px)] max-w-[22.5rem] rounded-e-2xl bg-white py-4;
  }

  .ryd-toast {
    @apply m-4 text-sm #{!important};

    .mdc-snackbar__surface {
      @apply rounded-lg #{!important};

      .mat-mdc-snack-bar-label {
        @apply px-3 py-2.5 #{!important};

        .ryd-button {
          @apply h-auto p-0 text-white hover:text-ryd-gray-200 #{!important};
        }
      }

      .mat-mdc-snack-bar-label:has(.ryd-icon) {
        @apply p-0 #{!important};

        .ryd-icon {
          @apply bg-ryd-gray-800/30;
        }

        path {
          @apply stroke-white;
        }
      }
    }

    &,
    &:has(.ryd-toast-primary) {
      .mdc-snackbar__surface {
        @apply bg-ryd-brand-600 #{!important};
      }
    }

    &:has(.ryd-toast-danger) {
      .mdc-snackbar__surface {
        @apply bg-ryd-error-600 #{!important};
      }
    }

    &:has(.ryd-toast-warning) {
      .mdc-snackbar__surface {
        @apply bg-ryd-warning-600 #{!important};
      }
    }
    &:has(.ryd-toast-success) {
      .mdc-snackbar__surface {
        @apply bg-ryd-success-600 #{!important};
      }
    }

    &:has(.ryd-toast-black) {
      .mdc-snackbar__surface {
        @apply bg-ryd-gray-900 text-white #{!important};

        .ryd-button {
          @apply h-auto p-0 text-ryd-brand-600 hover:text-ryd-brand-400 #{!important};
        }
      }
    }
  }

  .ryd-button-toggle {
    @apply rounded-lg border border-ryd-gray-300 shadow-ryd-xs #{!important};

    .mat-button-toggle {
      @apply flex-1 text-ryd-gray-400;

      path {
        @apply stroke-ryd-gray-400;
      }

      &.mat-button-toggle-checked {
        @apply bg-ryd-gray-25 text-ryd-gray-800 #{!important};

        path {
          @apply stroke-ryd-gray-800;
        }
      }

      .mat-button-toggle-label-content {
        @apply flex items-center justify-center gap-2 px-1 py-2.5;

        .mat-icon {
          @apply h-5 w-5;
        }

        span {
          @apply leading-tight;
        }
      }
    }
  }

  .ryd-expansion-panel {
    @apply shadow-none #{!important};

    .mat-expansion-panel-header {
      @apply h-auto border-b border-ryd-gray-200 py-[1.125rem] pe-1 ps-0;

      &.mat-expanded {
        @apply border-none;
      }

      .mat-expansion-indicator {
        @apply after:border-ryd-gray-800;
      }
    }

    .mat-expansion-panel-body {
      @apply py-0 ps-9;

      .mat-mdc-nav-list {
        @apply flex flex-col items-start gap-3.5 py-0;

        .mat-mdc-list-item {
          @apply h-auto px-0;
        }
      }
    }
  }

  .ryd-bottom-sheet {
    @apply h-[90vh] #{!important};
  }

  .ryd-bottom-sheet-sm {
    @apply h-fit #{!important};
  }

  .ryd-quantity {
    @apply flex h-8 overflow-hidden rounded border border-ryd-gray-300 shadow-ryd-xs;

    [mat-icon-button] {
      @apply flex h-full w-auto items-center justify-center rounded-none bg-white px-2 py-0 #{!important};
      --mdc-icon-button-state-layer-size: 100% !important;
      --mdc-icon-button-icon-size: 20px !important;

      .mat-icon {
        @apply h-5 w-5 #{!important};
      }

      .mat-mdc-button-touch-target {
        @apply w-full #{!important};
      }

      .mat-mdc-button-persistent-ripple {
        @apply rounded-none #{!important};
      }

      .mat-mdc-button-ripple {
        @apply rounded-none #{!important};
      }

      &[disabled] {
        @apply icon:stroke-ryd-gray-300;
      }
    }

    input {
      @apply w-10 border-none text-center text-sm font-semibold leading-10 text-ryd-gray-400 outline-none;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      & {
        -moz-appearance: textfield;
      }
    }
  }

  .ryd-crossed-out {
    @apply relative overflow-hidden;

    &:after {
      @apply absolute left-0 top-1/2 h-px w-full rotate-[5deg] bg-ryd-gray-400 content-[""];
    }
  }

  .ryd-products-grid {
    @apply grid place-content-center place-items-center gap-x-4 gap-y-6;
    grid-template-columns: repeat(auto-fill, minmax(11.25rem, 1fr));

    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
    }
  }

  .ryd-dialog {
    @apply h-full max-h-screen w-full max-w-[36.25rem] sm:max-h-[49.75rem] md:h-fit #{!important};

    .mat-mdc-dialog-surface {
      @apply rounded-none shadow-xl sm:rounded-xl #{!important};
    }
  }

  .ryd-card-skeleton {
    @apply h-60 w-[10.25rem] animate-pulse rounded bg-ryd-gray-50 md:rounded-xl;
  }
}

@font-face {
  font-family: 'Noto Sans Hebrew';
  src: url('./assets/font/NotoSansHebrew-Thin.ttf');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Noto Sans Hebrew';
  src: url('./assets/font/NotoSansHebrew-ExtraLight.ttf');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Noto Sans Hebrew';
  src: url('./assets/font/NotoSansHebrew-Light.ttf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Noto Sans Hebrew';
  src: url('./assets/font/NotoSansHebrew-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Noto Sans Hebrew';
  src: url('./assets/font/NotoSansHebrew-Medium.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Noto Sans Hebrew';
  src: url('./assets/font/NotoSansHebrew-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Noto Sans Hebrew';
  src: url('./assets/font/NotoSansHebrew-Bold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Noto Sans Hebrew';
  src: url('./assets/font/NotoSansHebrew_ExtraCondensed-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Noto Sans Hebrew';
  src: url('./assets/font/NotoSansHebrew_Condensed-Black.ttf');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
}

::before,
::after {
  --tw-content: '';
}

html,
body {
  font-family: 'Noto Sans Hebrew', sans-serif;
  height: 100%;
  margin: 0;
  color: #233251;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}

html {
  @apply scrollbar overflow-x-hidden bg-white;
}

.rounded-14-modal .mat-mdc-dialog-surface {
  @apply rounded-sup-14 #{!important};
}

.rounded-24-modal .mat-mdc-dialog-surface {
  @apply rounded-3xl #{!important};
}

.rounded-top-22-modal .mat-mdc-dialog-surface {
  @apply rounded-tr-[22px] #{!important};
  @apply rounded-tl-[22px] #{!important};
}

.first-purchase-modal .mat-mdc-dialog-surface {
  border-radius: 24px !important;
}

.product-details-toast .mdc-snackbar__surface {
  @apply rounded-full #{!important};
}

.light-toast .mdc-snackbar__surface {
  @apply bg-white #{!important};
  @apply mb-5 #{!important};
  @apply rounded-xl #{!important};
  @apply shadow-[0px_8px_28px_rgba(35,50,81,0.18)] #{!important};
}

.error-toast .mdc-snackbar__surface {
  @apply bg-white #{!important};
  @apply mb-6 #{!important};
  @apply rounded-xl #{!important};
  @apply text-black #{!important};
  @apply shadow-[0px_8px_28px_rgba(35,50,81,0.18)] #{!important};

  .mdc-snackbar__label {
    @apply text-sup-alert #{!important};
  }
}

.cdk-overlay-dark-backdrop {
  @apply bg-ryd-gray-800/40 #{!important};
}

@supports (margin: max(0px)) {
  body,
  .cdk-overlay-container {
    margin-top: max(env(safe-area-inset-top));
  }
}

.custom-modal {
  border-radius: 24px !important;
  overflow: hidden;
}

.mat-datepicker-content:has(.ryd-datepicker) {
  @apply rounded-xl bg-white p-4 shadow-ryd-xl #{!important};

  .mat-calendar-body-selected {
    @apply border-none bg-ryd-brand-600 text-white;
  }

  .mat-calendar-body-cell-content {
    @apply border-none;
  }

  .mat-calendar-body-label {
    @apply text-[#757575];
  }

  .mat-calendar-previous-button {
    @apply after:m-[18px];
  }

  .mat-calendar-next-button {
    @apply after:m-[18px];
  }

  .mat-calendar-table-header-divider:after {
    @apply bg-[#E2E2E2];
  }

  .mat-calendar-table-header {
    @apply text-[#757575];
  }

  .mat-calendar-header {
    @apply p-0;
  }
}

.mat-bottom-sheet-container {
  @apply bg-white #{!important};
}

.cdk-overlay-pane:has(> .mat-mdc-select-panel, .mat-mdc-autocomplete-panel) {
  @apply translate-y-1.5;

  .mat-mdc-select-panel,
  .mat-mdc-autocomplete-panel {
    @apply rounded-lg border border-ryd-gray-100 bg-white py-1 shadow-ryd-lg #{!important};

    .mat-mdc-option {
      @apply text-ryd-gray-900 hover:bg-ryd-gray-25 #{!important};

      .mdc-list-item__primary-text {
        @apply text-ryd-gray-900 #{!important};
      }
    }

    .mat-mdc-option-active {
      @apply bg-transparent #{!important};
    }

    .mdc-list-item--selected {
      @apply bg-ryd-gray-25 text-ryd-gray-900 #{!important};
    }

    :not(.mat-mdc-option-multiple).mat-pseudo-checkbox-checked {
      @apply content-ryd-select-check #{!important};
    }

    .mat-mdc-option-multiple {
      .mat-pseudo-checkbox {
        @apply rounded border border-ryd-gray-300 p-px;
      }

      .mat-pseudo-checkbox-checked {
        @apply border-ryd-brand-600 bg-ryd-brand-50 content-ryd-checked;
      }
    }

    .mat-mdc-option-multiple:hover {
      .mat-pseudo-checkbox {
        @apply border-ryd-brand-600 bg-ryd-brand-50 #{!important};
      }
    }

    .mat-mdc-option-multiple:active {
      .mat-pseudo-checkbox {
        @apply shadow-ryd-brand-100;
      }
    }
  }
}
