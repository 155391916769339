.mat-button-wrapper {
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.scroll {
  @apply relative h-full;
}

.scroll-inner {
  @apply absolute inset-0 overflow-auto;
}

.mat-dialog-container {
  padding: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.confirm-dialog {
  border-radius: 14px;
  overflow: hidden;
}

.mat-bottom-sheet-container {
  max-height: 100% !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  border-radius: 22px 22px 0 0 !important;
}
